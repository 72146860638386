@import url("https://use.typekit.net/xas7gbq.css");

/* typography */
*, h1, h2, h3, h4, h5, h6, p, a, label, button, input {
    font-family: effra, sans-serif !important;
    font-style: normal;
    color: white;
}

h1, h2, h3, h4, h5, h6, label, p {
    user-select: none;
}

h1 {
    @apply font-black text-5xl md:text-10xl;
}

h2 {
    @apply font-black text-4xl md:text-8xl;
}

h3 {
    @apply font-bold text-3xl md:text-6xl;
}

h4 {
    @apply font-medium text-2xl md:text-4xl;
}

h5 {
    @apply font-medium text-xl md:text-2xl;
}

h6 {
    @apply font-medium text-lg md:text-xl;
}

p {
    @apply font-normal text-base;
}

.custom-alert-dialog {
    --background: var(--primary-color);
    --backdrop-opacity: 0.8;
    color: white;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

*::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-shade);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-tint);    /* color of the scroll thumb */
}
*::-webkit-scrollbar-thumb:active {
    background-color: var(--primary-color);    /* color of the scroll thumb */
}