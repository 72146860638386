.statusicon .danger :global(.ionicon-fill-none, .ionicon-stroke-width) {
  color: var(--danger) !important;
} 

.statusicon :global(.unknown path) {
  color: red !important;
} 

.statusicon .success path {
    color: var(--success) !important;
}

.icon:global(ion-icon) {
    color: red;
}