.content {
    transition: all .25s ease;
}

.sideIcon {
    height: 20px;
    width: 20px;
}

.collapsed {
    margin-left: 80px;
}

.extended {
    margin-left: 200px;
}

.sider {
    overflow: auto;
    height: calc(100vh - 48px);
    position: fixed!important;
    left: 0;
    top: 0;
    bottom: 0;
}

.neon {
    text-shadow: 0 0 14px rgb(241 138 52 / 70%), 0 0 5.84887px rgb(241 138 52 / 71%), 0 0 3.12708px rgb(241 138 52 / 50%), 0 0 1.75302px rgb(241 138 52 / 50%), 0 0 0.931014px rgb(241 138 52 / 40%), 0 0 0.387416px rgb(241 138 52 / 28%);
}