/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */


/** Ionic CSS Variables **/
:root {
    /** Overrides */
    --ion-background-color: var(--primary-color);

    /** primary **/
    --ion-color-primary: var(--primary-color);
    --ion-color-primary-rgb: 34, 43, 69;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: var(--primary-shade);
    --ion-color-primary-tint: var(--primary-tint);

    /** secondary **/
    --ion-color-secondary: var(--secondary-color);
    --ion-color-secondary-rgb: 26,33,56;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: var(--secondary-shade);
    --ion-color-secondary-tint: var(--secondary-tint);

    /** tertiary **/
    --ion-color-tertiary: var(--accent-base);
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade:  var(--accent-base-shade);
    --ion-color-tertiary-tint: var(--accent-base-hover);

    --ion-text-color: #ffffff;

    /** success **/
    --ion-color-success: var(--success);
    --ion-color-heart: blue;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #85e34b;
    --ion-color-success-tint: #5a9b32;

    /** warning **/
    --ion-color-warning: var(--warning);
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: var(--danger);
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #ac3a3a;
    --ion-color-danger-tint: #ed576b;

    /* custom */
    --ion-color-primary-brand: var(--primary-color);
    --ion-color-primary-brand-rgb: 34, 43, 69;
    --ion-color-primary-brand-contrast: #ffffff;
    --ion-color-primary-brand-contrast-rgb: 255, 255, 255;
    --ion-color-primary-brand-shade:var(--primary-shade);
    --ion-color-primary-brand-tint: var(--primary-tint);

    --ion-color-white-background: #FBFBFB;
    --ion-color-white-background-rgb: 251, 251, 251;
    --ion-color-white-background-contrast: #000000;
    --ion-color-white-background-contrast-rgb: 0, 0, 0;
    --ion-color-white-background-shade: #dddddd;
    --ion-color-white-background-tint: #fbfbfb;

    --ion-color-black-text: #0E0E0E;
    --ion-color-black-text-rgb: 14, 14, 14;
    --ion-color-black-text-contrast: #ffffff;
    --ion-color-black-text-contrast-rgb: 255, 255, 255;
    --ion-color-black-text-shade: #0c0c0c;
    --ion-color-black-text-tint: #262626;

    --ion-color-gray-text: #909090;
    --ion-color-gray-text-rgb: 144, 144, 144;
    --ion-color-gray-text-contrast: #000000;
    --ion-color-gray-text-contrast-rgb: 0, 0, 0;
    --ion-color-gray-text-shade: #7f7f7f;
    --ion-color-gray-text-tint: #9b9b9b;

    --ion-color-light-gray: #EAEAEA;
    --ion-color-light-gray-rgb: 234, 234, 234;
    --ion-color-light-gray-contrast: #000000;
    --ion-color-light-gray-contrast-rgb: 0, 0, 0;
    --ion-color-light-gray-shade: #cecece;
    --ion-color-light-gray-tint: #ececec;

    --ion-color-white-text: #FFFFFF;
    --ion-color-white-text-rgb: 255, 255, 255;
    --ion-color-white-text-contrast: #000000;
    --ion-color-white-text-contrast-rgb: 0, 0, 0;
    --ion-color-white-text-shade: #e0e0e0;
    --ion-color-white-text-tint: #ffffff;
}

.ion-color-primary-brand {
    --ion-color-base: var(--ion-color-primary-brand);
    --ion-color-base-rgb: var(--ion-color-primary-brand-rgb);
    --ion-color-contrast: var(--ion-color-primary-brand-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-brand-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-brand-shade);
    --ion-color-tint: var(--ion-color-primary-brand-tint);
}

.ion-color-white-background {
    --ion-color-base: var(--ion-color-white-background);
    --ion-color-base-rgb: var(--ion-color-white-background-rgb);
    --ion-color-contrast: var(--ion-color-white-background-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-background-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-background-shade);
    --ion-color-tint: var(--ion-color-white-background-tint);
}

.ion-color-black-text {
    --ion-color-base: var(--ion-color-black-text);
    --ion-color-base-rgb: var(--ion-color-black-text-rgb);
    --ion-color-contrast: var(--ion-color-black-text-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-text-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-text-shade);
    --ion-color-tint: var(--ion-color-black-text-tint);
}

.ion-color-gray-text {
    --ion-color-base: var(--ion-color-gray-text);
    --ion-color-base-rgb: var(--ion-color-gray-text-rgb);
    --ion-color-contrast: var(--ion-color-gray-text-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-text-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-text-shade);
    --ion-color-tint: var(--ion-color-gray-text-tint);
}

.ion-color-light-gray {
    --ion-color-base: var(--ion-color-light-gray);
    --ion-color-base-rgb: var(--ion-color-light-gray-rgb);
    --ion-color-contrast: var(--ion-color-light-gray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-gray-shade);
    --ion-color-tint: var(--ion-color-light-gray-tint);
}

.ion-color-white-text {
    --ion-color-base: var(--ion-color-white-text);
    --ion-color-base-rgb: var(--ion-color-white-text-rgb);
    --ion-color-contrast: var(--ion-color-white-text-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-text-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-text-shade);
    --ion-color-tint: var(--ion-color-white-text-tint);
}