.accent{
    color: var(--accent-base);
}

/* Positions the login page statically in the center */
.loginPage {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
    .loginPage {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;

        top: 0;
        left: 0;
        transform: none;
    }
}