.contentArea {
    height: calc(100vh - 150px);
}

.cardPicker {
   border: 2px solid transparent;
}

.cardPickerSelected {
    border: 2px solid var(--accent-base);
}