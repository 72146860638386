.accent {
    color: var(--accent-base);
}

.segments{
    width: 400px;
    margin: auto;
    --background: var(--accent-base-shade);
}
.segments ion-segment-button::part(indicator-background) {
    background: var(--accent-base);
}

.paddedSides {
    @apply px-[125px];
}

.homepage h2 {
    @apply text-xl text-center mt-6
}

.textDisabled {
    opacity: 0.5;
}
.textEnabled {
    opacity: 1;
}

.homepage button p {
    font-weight: bold;
}

.homepage :global(ion-col) {
    padding: 3px;
}

.homepage :global(ion-fab-button::part(native)) {
    --background-hover: var(--accent-base-hover);
}

.startRaceButton {
    background-color: var(--ion-color-success);
}
.startRaceButton:hover {
    background-color: var(--ion-color-success-shade);
}
.disconnectButton {
    background-color: var(--ion-color-danger);
}
.disconnectButton:hover {
    background-color: var(--ion-color-danger-tint);
}