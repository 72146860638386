.loginform input:focus-visible {
    outline: 1px solid var(--primary-color);
    outline-color: var(--accent-base);
}

.popup {
    --background: var(--primary-color);
    --backdrop-opacity: 0.8;
    color: white;
}
