:root{
    --primary-color: #222b45;
    /* --primary-color: #173a17; */

    --primary-color-rgb: 34, 43, 69;
    --primary-shade: #394771;
    --primary-tint: #6f84c2;

    --secondary-color: #1a2138;
   /* --secondary-color: #254225; */

    --secondary-color-rgb: 26,33,56;
    --secondary-shade: #313e6c;
    --secondary-tint: #384778;

    --current-theme-accent: var(--ant-primary-color);
    --accent-base: #f57c00;
    --accent-base-hover: #ef9136;
    --accent-base-shade: #D55C26;
    --accent-wiki: #Db8eff;
    --accent-wiki-hover: #ad5dd3;
    --accent-library: #71b4e4;
    --accent-library-hover: #5b90b5;
    --accent-codehub: #93c979;
    --accent-codehub-hover: #74a75d;

    --input-border-color: #848484;

    --success: #75d03d;
    --warning: #EDD036;
    --danger: #d53e3e;
    --unknown: #A0A0A0;

    --background: var(--primary-color);
    --color: white;
}