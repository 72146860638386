/* Modal overwrites */

.okButton {
    background-color: var(--accent-base);
}
.okButton:hover {
    background-color: var(--accent-base-hover) !important;
}
.okButton:disabled, .okButton:disabled:hover {
    background-color: var(--primary-color) !important;
}

.modal {
    padding: 2rem;
}

:global(.ant-modal) {
    width: 700px !important;
    padding: 2rem !important;
}

:global(.ant-btn:hover) {
    background: var(--accent-base-hover) !important;
}

.modal :global(.ant-modal-content), .modal :global(.ant-modal-header) {
  @apply bg-primary rounded;
}

.modal :global(.ant-modal-body), .modal :global(.ant-modal-header), .modal :global(.ant-modal-footer) {
  @apply py-6;
}

.modal :global(.ant-modal-title){
    color: white;
    @apply text-4xl text-center;
}

:global(.ant-btn:hover, .ant-btn:focus, .ant-btn:active) {
    background: var(--secondary-shade) !important;
}

.modal .input {
    border: 1px solid var(--input-border-color)
}

.modal .input:hover {
    border: 1px solid var(--accent-base);
    transition: 0.2s ease-in;
}

/* switch */

.modal :global(.ant-switch-checked:focus), .modal :global(.ant-switch-checked) {
    box-shadow: 0 0 0 2px var(--input-border-color) !important;
}

/* link input */
.linkInput {
    width: 100%;
    height: 33px;
    border: 1px solid var(--input-border-color);
}
