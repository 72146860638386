.button:disabled {
    cursor: not-allowed !important;
    background-color: rgba(128, 128, 128, 0.2)!important;
}

.button:disabled *{
    color: rgba(128, 128, 128, 0.526)!important;
}

.selected {
    border: 2px solid white;
    opacity: 0.6;
}

.deselected {
    border: 2px solid transparent;
}